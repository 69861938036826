import React, { useState, createContext } from "react";

export const UsersContext = createContext({
  currentUser: "",
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState("");
  const value = { currentUser, setCurrentUser };
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
