import React, { useState, createContext } from "react";

export const FileInfoContext = createContext({
  fileInfoData: "",
});

export const UploadImageProvider = ({ children }) => {
  const [fileInfoData, setfileInfoData] = useState("");
  const value = { fileInfoData, setfileInfoData };
  return (
    <FileInfoContext.Provider value={value}>
      {children}
    </FileInfoContext.Provider>
  );
};
